import React from 'react'
import logo from "./logo.svg";
import {
  Box,
  Image,
  Text,
  Link,
  HStack,
  Heading,
  Switch as ToggleSwitch,
  useColorMode,
  VStack,Divider,Menu,Pressable,Input,Button,useMediaQuery,ChevronDownIcon,SunIcon,MoonIcon,
   IconButton, Icon,  Actionsheet, useDisclose
} from "native-base";
import { useLocation } from 'react-router-dom';


import { Provider } from 'react-redux';
import useStore from './redux-store';
import { PersistGate } from 'redux-persist/integration/react';

import { BrowserRouter as Router, Switch, Route,useHistory,Redirect  } from 'react-router-dom'

import LandingPage from './components/pages/LandingPage'
import LoginPage from './components/pages/LoginPage'
import BuildPage from './components/pages/BuildPage'
// import QuizPage from './components/pages/QuizPage'
import RegisterPage from './components/pages/RegisterPage'
import ForgetPasswordPage from './components/pages/ForgetPasswordPage'
import HomePage from './components/pages/HomePage'
import {LoginRequired,clearUser,SavedPageHistory,getUser} from './helper/authentication'
import { Helmet } from 'react-helmet';


import './App.css'
import './Circle.css'
import Logo from './assets/images/logo-white.png'
import LogoGreen from './assets/images/logo-green.png'
// import LogoBlack from './assets/images/logo-black.png'
import LogoColor from './assets/images/logo-color.png'


const LogOut = ({verifyLoggedIn})=>{

const history = useHistory()

React.useEffect( () =>{

clearUser()
history.push('/login')
localStorage.removeItem("prev_url")
verifyLoggedIn(false)

},[])


}


const Footer = () => {
  const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);

    return ( 

      isPortrait ?

      <VStack p={3} justifyContent='space-between' alignItems='center' style={ FooterStyle }>
        <Text style={{color:'silver'}}    >Copyright &copy; 3x0  </Text>
        <HStack space={3}  >
            <li>
              <a href="https://3x0.pythonanywhere.com/" style={{color:'silver'}} >About Us</a>
            </li>
            <li>
              <a href="/intl/en/policies/privacy/" style={{color:'silver'}}>Privacy</a>
            </li>
            <li>
              <a href="/intl/en/policies/terms/" style={{color:'silver'}}>Terms & Conditions</a>
            </li>
          </HStack>

        <Text  style={{color:'silver'}}  >QuickSum@2023 </Text>

</VStack>  :  <HStack p={3} justifyContent='space-between' alignItems='center' style={ FooterStyle }>
        <Text    style={{color:'silver'}} >Copyright &copy; 3x0  </Text>
        <HStack space={3}  >
            <li>
              <a href="https://3x0.pythonanywhere.com/" style={{color:'silver'}} >About Us</a>
            </li>
            <li>
              <a href="/intl/en/policies/privacy/" style={{color:'silver'}}>Privacy</a>
            </li>
            <li>
              <a href="/intl/en/policies/terms/" style={{color:'silver'}}>Terms & Conditions</a>
            </li>
          </HStack>

        <Text   style={{color:'silver'}} >QuickSum@2023 </Text>

</HStack> )
}


  // Inline styles
  const containerStyle = {
    margin: '20px',
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  };

  const labelStyle = {
    fontSize: '1.2em',
    marginBottom: '10px',
    color: '#333',
    fontWeight: 'bold'
  };

  const selectStyle = {
    width: '100%',
    maxWidth: '350px',
    minWidth: '200px',
    padding: '3px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '1em',
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
    outline: 'none',
    marginTop: '0px',
    height:'40px'
  };

  const selectStyleFocused = {
    ...selectStyle,
    borderColor: '#007bff',
    boxShadow: '0 0 5px rgba(0, 123, 255, 0.5)'
  };



const Header = ({loggedIn}) => {

   const [shouldOverlapWithTrigger] = React.useState(false);
   const [shouldOverlapWithTrigger2] = React.useState(false);
  const [position, setPosition] = React.useState("auto");
 const [history, setHistory] = React.useState({});

  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclose();
  const appVersion = "1.0.0";


const historyl = useHistory()

 const location = useLocation();
  const isHomePage = location.pathname === '/';

 const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);


 React.useEffect(() => {
    const storedHistory = JSON.parse(localStorage.getItem('history')) || {};
    setHistory(storedHistory);
  }, []);

 const use_cases = [
    {
      "title": "How to turn Lengthy Documents into Summarized Contents You Can Interact With using QuickSum"
    },{
      "title": "Streamlining Logistics Document Processing for Faster Operations"
    },
    {
      "title": "Simplifying Legal Document Review with QuickSum"
    },
    {
      "title": "Efficient Invoice Data Extraction for Finance Teams"
    },
    {
      "title": "Automating Report Summarization for Business Insights"
    },
    {
      "title": "Enhancing Academic Research with Quick Document Summaries"
    },
    {
      "title": "Simplifying Insurance Policy Analysis with QuickSum"
    },
    {
      "title": "Fast Extraction of Data from Real Estate Contracts"
    },
    {
      "title": "Boosting Operational Efficiency with Intelligent Document Processing"
    },
    {
      "title": "How QuickSum Simplifies Research Paper Analysis for Students"
    }
  ]



  const handleSelectChange = (selectedId) => {
    if (selectedId) {
       localStorage.setItem("historyview",selectedId)
       historyl.push('/summarize')
    } else {
      // alert("No content found for the selected ID");
    }
  };

    return (   
        

isPortrait ? !isHomePage &&

 <>
   
      <Box  safeAreaTop bg={colorMode === "dark" ? "gray.900" : "green.500"} position="absolute" top="0" left="0" right="0" zIndex={99} />
      <div className="fixed-element" style={{width:'100vw'}} >
      <HStack bg={colorMode === "dark" ? "gray.800" : "green.600"} px="4" py="1" justifyContent="space-between" alignItems="center" w='100vw' >
        <IconButton
          icon={ <svg  style={{color:'white',fontWeight:'bold'}} width="24" height="26" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>}
          onPress={onOpen}
        />
        
         <HStack space={1} alignItems='center' >
      <img src={LogoGreen} style={{height:'34px' ,color:'white'}} />
        <Text fontWeight='bold' fontSize='xl' color='white'  mx={1} mt={2}  style={{ fontFamily: "'Josefin Sans', serif", fontSize:'1.7rem'
}}  >QuickSum  </Text>
</HStack>

         
         <ToggleDarkMode /> 
      </HStack>
      </div>

     
      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <Actionsheet.Content>
          <VStack space={3} w="100%" px="4">
            <Pressable onPress={() =>  window.location.href = '/' }>
            <HStack space={2}  >
            <svg  width="18" height="18" fill="currentColor" class="bi bi-house-check" viewBox="0 0 16 16">
  <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.708L8 2.207l-5 5V13.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 2 13.5V8.207l-.646.647a.5.5 0 1 1-.708-.708z"/>
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.707l.547.547 1.17-1.951a.5.5 0 1 1 .858.514"/>
</svg>
              <Text style={{ fontFamily: "'Josefin Sans', serif"}} fontSize="md" bold> Home</Text>
              </HStack>
            </Pressable>
          
            <Divider />
               <Pressable onPress={() =>  window.location.href = '/summarize' }>
            <HStack space={2}  >
            <svg  width="18" height="18" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
  <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5"/>
  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
</svg>
              <Text style={{ fontFamily: "'Josefin Sans', serif"}} fontSize="md" bold> Summarize Now</Text>
              </HStack>
            </Pressable>
            <Divider />
            <HStack alignItems="center"  >
              
              <ToggleDarkMode label="Change Color Mode" />
            </HStack>
          
            <Divider />

            <Text fontSize="sm" color="gray.500">QuickSum: Read Less. Do More</Text>
            <Text fontSize="sm" color="gray.500">Version: {appVersion} @ 3x0</Text>
          </VStack>
        </Actionsheet.Content>
      </Actionsheet>
    </>




:
 <HStack justifyContent='space-between'  pb={2} px={3} alignItems='center' > 
      <HStack space={1} alignItems='center' >
      <img src={Logo} style={{height:'74px',marginTop:0}} />
      <Link href="/" _text={{textDecoration:'none'}} >
        <Text fontWeight='bold'   mx={1}  className=" fascinate my-0 " style={{ fontSize:'2.4rem', color:"green",
      fontFamily: "'Josefin Sans', serif",
  fontOpticalSizing: "auto",
  fontStyle: "normal"

}}  >QuickSum  </Text>
 </Link>
 
      </HStack>

 

<HStack space='7' alignItems="center" > 


 <Menu px={2}
         
            trigger={(triggerProps) => (
              <Pressable {...triggerProps}  className="tab" >
                <HStack alignItems='center' space={2}  > 
                <Text style={{fontFamily: "'Noto Sans JP', serif", fontSize:'17px'}}> Use Cases</Text>
                 <ChevronDownIcon color='gray' /></HStack>
              </Pressable>
            )}
          >
            { use_cases.map((e) => (
              <Menu.Item key={e} onPress={() => handleSelectChange(e) }>
                <a href={`/blog/${e.title}`} style={{textDecoration:'none'}}> <Text style={{fontFamily: "'Noto Sans JP', serif",}} >{e.title}</Text> </a>
              </Menu.Item>
            ))}
          </Menu>



           <a href="/blogs" ml={2}  style={{textDecoration:'none'}}> 
                <Text style={{fontFamily: "'Noto Sans JP', serif", fontSize:'17px'}}>Blog</Text>
 </a>


  <Link to="/summarize" ml={2}>
                    <button className="big_button" style={{
                        textDecoration:'none',   height:"45px" , color:"white", background:'green'
                    }} ><span>Get Started <span>&#187;</span></span> </button>
                </Link>

<Menu  
            
            trigger={(triggerProps) => (
              <Pressable {...triggerProps}  className="tab" >
                <HStack alignItems='center' space={2}  > <Text style={{fontFamily: "'Noto Sans JP', serif", fontSize:'17px'}}> 
                History </Text>
                 <ChevronDownIcon color='gray' /></HStack>
              </Pressable>
            )}
          >
            {Object.keys(history).map((e) => (
              <Menu.Item key={e} onPress={() => handleSelectChange(e) }>
                <Text style={{fontFamily: "'Noto Sans JP', serif"}} > {e}</Text>
              </Menu.Item>
            ))}
          </Menu>



   

        <ToggleDarkMode /> 
        </HStack>
        </HStack>
    )
}

const FooterStyle = {
    background: "#222",
    fontSize: ".8rem",
    color: "#fff",
    width: "100%",
    opacity: ".9",
    position:"absolute",
    bottom:'0vh'
}

const HeaderStyle = {
 
    fontSize: ".8rem",
    color: "#fff",
    position: "absolute",
    top: 0,
    padding: ".6rem",
    margin: 0,
    width: "100%",
    opacity: ".5"
}


function App() {
  const [loggedIn,setLoggedIn] = React.useState()
  const [sideMenu,setSideMenu] = React.useState('Collections')


  const { colorMode } = useColorMode();
  const Home = LoginRequired(SavedPageHistory(HomePage))
  const {store,persistor} = useStore({})

 const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);


React.useEffect(()=>{
localStorage.removeItem('historyview') 

},[]) 


  return (
       <Provider store={store} >  
 <PersistGate loading={null} persistor={persistor}>
    <Router >

<Box 
      bg={ loggedIn ? "coolGray.100" :colorMode === "light" ? "coolGray.50" : "coolGray.900"}
    >
    <Header loggedIn={loggedIn} />
  <Helmet > 
                <meta name="description" content="Discover the best AI summarization tools for quickly and efficiently summarizing long text. Enhance your productivity with our automated text summarization software." />
                <meta name="keywords" content="AI text summarization, automated summarization, document summarizer, AI-powered summarization, business report summarization, meeting notes summarization, natural language processing" />
                <meta property="og:title" content="AI Summarizer: Effortlessly Summarize Long Text with Our Tool" />
                <meta property="og:description" content="Discover the best AI summarization tools for quickly and efficiently summarizing long text. Enhance your productivity with our automated text summarization software." />
                <meta property="og:image" content="https://res.cloudinary.com/djpcokxvn/image/upload/v1727230433/2024-09-25_03_12_49-QuickSum_and_10_more_pages_-_Personal_-_Microsoft_Edge_i6ylfp.png" />
                <meta property="og:url" content="https://summarizeaibot.netlify.app/" />

      </Helmet>
     <Box  h={isPortrait ? '100vh':null}>
     <Switch  h={isPortrait ? '40vh':null}>
                    <Route exact path="/" component={ LandingPage } />
                    <Route path="/login" component={ LoginPage } />
                    <Route path="/summarize" component={ BuildPage } />
  
                    <Route path="/register" component={ RegisterPage } />
                    <Route path="/forget-password" component={  ForgetPasswordPage } />
                    <Route path="/collections" render={(props) => <Home {...props} verifyLoggedIn={e=>setLoggedIn(e) } sideMenu={sideMenu} setSideMenu={setSideMenu} />}   />
                    <Route path="/logout" render={(props) => <LogOut {...props} verifyLoggedIn={e=>setLoggedIn(e) } />}   />
                
                <Route render={() => <Redirect to="/summarize" />} />
                </Switch>
                </Box>

                           { isPortrait ? <></>: <Footer />}
      
 
    </Box>

    </Router>
    </PersistGate>
    </Provider>
    
  );
}

function ToggleDarkMode({label=""}) {
  const { colorMode, setColorMode } = useColorMode();

  // Function to switch to light mode
  const switchToLight = () => {
    setColorMode('light');
  };

  // Function to switch to dark mode
  const switchToDark = () => {
    setColorMode('dark');
  };

  return (
    <HStack space={2} alignItems="center" justifyContent={label ? 'space-between':""} >
      
      { colorMode === 'light' ?
      <MoonIcon
        onClick={switchToDark}
        cursor="pointer"
        boxSize={6} size='md'
        color={ label ? 'gray.500': 'gray.100' }
      />
     : <SunIcon
        onClick={switchToLight}
        cursor="pointer" size='md'
        boxSize={6}
        color={  'yellow.400'}
      />
    }
    {label ? <Pressable   onPress={ colorMode === 'light'? switchToDark:switchToLight} >
     <Text style={{fontFamily: "'Josefin Sans', serif"}} fontSize="md" bold>Change Color Mode</Text>  </Pressable>:<></>
  } </HStack>
  );
}


export default App;
