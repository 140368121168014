import React,{useRef} from 'react'
import { Link } from 'react-router-dom'
import {VStack,HStack,Divider,Text,useMediaQuery,Box,Image,Center,Button} from 'native-base'
import '../../App.css'
// import {Banner300by250,Banner728by90} from "../Banner";
import VSC from '../../assets/images/video-screen-capture.svg'
import SRA from '../../assets/images/screen-recorder-app.svg'
import UpgradeButton from '../UpgradeButton';
import MainPage from '../../assets/images/main_page.png'


 
 
const QuickSumBenefits = () => {

     const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);


  return (isLandScape ?
    <HStack space={4} alignItems="center" justifyContent="center" p={4} mb={'3%'}  wrap="wrap"  >
      {/* Instant Clarity */}
      <Box h='230px'
        p={4}
        bg="white"
        shadow={2}
        borderWidth={1}
        borderColor="gray.300"
        rounded="md"
        _hover={{
          borderTopColor: 'green.500',
          borderTopWidth: 4,
        }}
        borderTopWidth={3}
        borderTopColor="transparent"
        w="25%"
        textAlign="center"
      >
        <Text fontWeight="bold" fontSize="xl" color='green.700' s >
       Simplify Information
        </Text>
        <Text mt={2} color="gray.500" fontSize="1.15rem" style={{ fontFamily: "'Noto Sans JP', serif",lineHeight:'2rem'}}>
         Break down complex documents in seconds, not hours. Just upload your file, and QuickSum provides easy-to-understand summaries.
        </Text>
      </Box>

 
      <Box h='230px'
        p={4}
        bg="white"
        shadow={2}
        borderWidth={1}
        borderColor="gray.300"
        rounded="md"
        _hover={{
          borderTopColor: 'green.500',
          borderTopWidth: 4,
        }}
        borderTopWidth={4}
        borderTopColor="green.500"
        w="25%"
        textAlign="center"
      >
        <Text fontWeight="bold" fontSize="xl" color='green.700' s >
          Reduce Misunderstandings
        </Text>
        <Text mt={2} color="gray.500" fontSize="1.15rem" style={{ fontFamily: "'Noto Sans JP', serif",lineHeight:'2rem'}}>
         Summarized sections make your content clearer. People understand key points faster compared to reading lengthy texts.
        </Text>
      </Box>

 
      <Box h='230px'
        p={4}
        bg="white"
        shadow={2}
        borderWidth={1}
        borderColor="gray.300"
        rounded="md"
        _hover={{
          borderTopColor: 'green.500',
          borderTopWidth: 4,
        }}
        borderTopWidth={3}
        borderTopColor="transparent"
        w="25%"
        textAlign="center"
      >
        <Text fontWeight="bold" fontSize="xl" color='green.700' s   style={{ fontFamily: "'Noto Sans JP', serif"}}>
          Boost Productivity
        </Text>
        <Text mt={2} color="gray.500" fontSize="1.15rem" style={{ fontFamily: "'Noto Sans JP', serif",lineHeight:'2rem'}}>
         Skip time-consuming reviews. QuickSum lets your team access and interact with document insights anytime, at their convenience.
        </Text>
      </Box>
    </HStack>   

    :

      <VStack space={4} alignItems="center" justifyContent="center" p={4} mb={'3%'}  wrap="wrap"  >
     
           <Box h='250px'
        p={4}
        bg="white"
        shadow={2}
        borderWidth={1}
        borderColor="gray.300"
        rounded="md"
        _hover={{
          borderTopColor: 'green.500',
          borderTopWidth: 4,
        }}
        borderTopWidth={4}
        borderTopColor="green.500"
        w="95%"
        textAlign="center"
      >
        <Text fontWeight="bold" fontSize="lg" color='green.700' s >
          Reduce Misunderstandings
        </Text>
        <Text mt={2} color="gray.500" fontSize="1rem" style={{ fontFamily: "'Noto Sans JP', serif",lineHeight:'2rem'}}>
         Summarized sections make your content clearer. People understand key points faster compared to reading lengthy texts.
        </Text>
      </Box>

      <Box h='250px'
        p={4}
        bg="white"
        shadow={2}
        borderWidth={1}
        borderColor="gray.300"
        rounded="md"
        _hover={{
          borderTopColor: 'green.500',
          borderTopWidth: 4,
        }}
        borderTopWidth={3}
        borderTopColor="transparent"
        w="95%"
        textAlign="center"
      >
        <Text fontWeight="bold" fontSize="lg" color='green.700' s >
       Simplify Information
        </Text>
        <Text mt={2} color="gray.500" fontSize="1rem" style={{ fontFamily: "'Noto Sans JP', serif",lineHeight:'2rem'}}>
         Break down complex documents in seconds, not hours. Just upload your file, and QuickSum provides easy-to-understand summaries.
        </Text>
      </Box>

 


 
      <Box h='250px'
        p={4}
        bg="white"
        shadow={2}
        borderWidth={1}
        borderColor="gray.300"
        rounded="md"
        _hover={{
          borderTopColor: 'green.500',
          borderTopWidth: 4,
        }}
        borderTopWidth={3}
        borderTopColor="transparent"
        w="95%"
        textAlign="center"
      >
        <Text fontWeight="bold" fontSize="lg" color='green.700' s   style={{ fontFamily: "'Noto Sans JP', serif"}}>
          Boost Productivity
        </Text>
        <Text mt={2} color="gray.500" fontSize="1rem" style={{ fontFamily: "'Noto Sans JP', serif",lineHeight:'2rem'}}>
         Skip time-consuming reviews. QuickSum lets your team access and interact with document insights anytime, at their convenience.
        </Text>
      </Box>
    </VStack>

  );
};





 
const SummarizeAiUseCases = () => {

       const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);



  const use_cases_1 = [
  {
    'label':'Researchers',
    'description':'Extract key insights from lengthy research papers and documents quickly.'
  },{
    'label':'Legal Summaries',
    'description':'Summarize legal reports and cases, speeding up document review.'
  },{
    'label':'Business Reports',
    'description':'Quickly digest and report on business reports and data insights.'
  },{
    'label':'Educational Handouts & Textbooks',
    'description':'Summarize textbooks, educational handouts, and academic materials effortlessly.'
  },
  ]

  const use_cases_2 = [
  {
    'label':'Medical & Clinical Reports',
    'description':'Extract vital information from medical and clinical reports in seconds.'
  },{
    'label':'News Article Summaries',
    'description':'Extract key points from news articles for quick insights.'
  },{
    'label':'Product Reviews & Comparisons',
    'description':'Summarize and compare products for easier decision-making.'
  },{
    'label':'Grant & Proposal Summaries',
    'description':'Summarize grant proposals and research documents with ease.'
  },
  ]
  return (
    <VStack alignItems="center" p={6} space={4} mb={isPortrait ? '10%':'0px'}  >
   { isLandScape ?   <HStack justifyContent="center" w="100%" wrap="wrap" space={6}>
        {use_cases_1.map(e=>(
  <Box
          p={5} key={e.label}
          bg="white"
          shadow={3} style={{minHeight:'150px'}}
          rounded="lg"
                 boxShadow="  rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 150px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"

          w="20%"
          textAlign="center" 
        >
          <Text fontWeight="bold" fontSize="lg" color='green.700' style={{fontFamily: "'Noto Sans JP', serif", fontSize:'19px'}} >
            {e.label}
          </Text>
          <Text mt={2} color="gray.600" style={{fontFamily: "'Noto Sans JP', serif", fontSize:'17px'}} >
            {e.description}
          </Text>
        </Box>

          ))}
      
       
      </HStack> :
       <VStack justifyContent="center" alignItems='center' w="100%"  space={6} mx={3} >
        {use_cases_1.map(e=>(
  <Box
          p={5} key={e.label}
          bg="white"
          shadow={3} style={{minHeight:'150px'}}
          rounded="lg"
           boxShadow="  rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 150px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
       
          textAlign="center" 
        >
          <Text fontWeight="bold" fontSize="lg" color='green.700' style={{fontFamily: "'Noto Sans JP', serif", fontSize:'17px'}} >
            {e.label}
          </Text>
          <Text mt={2} color="gray.600" style={{fontFamily: "'Noto Sans JP', serif", fontSize:'15px'}} >
            {e.description}
          </Text>
        </Box>

          ))}
             
      </VStack>

 
    }

          

{ isLandScape ?   <HStack justifyContent="center" w="100%" wrap="wrap" space={6}>
        {use_cases_2.map(e=>(
  <Box
          p={5} key={e.label}
          bg="white"
          shadow={3} style={{minHeight:'150px'}}
          rounded="lg"
                 boxShadow="  rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 150px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"

          w="20%"
          textAlign="center" 
        >
          <Text fontWeight="bold" fontSize="lg" color='green.700' style={{fontFamily: "'Noto Sans JP', serif", fontSize:'19px'}} >
            {e.label}
          </Text>
          <Text mt={2} color="gray.600" style={{fontFamily: "'Noto Sans JP', serif", fontSize:'17px'}} >
            {e.description}
          </Text>
        </Box>

          ))}
      
       
      </HStack> :
       <VStack justifyContent="center" alignItems='center' w="100%"  space={6} mx={3} >
        {use_cases_2.map(e=>(
  <Box
          p={5} key={e.label}
          bg="white"
          shadow={3} style={{minHeight:'150px'}}
          rounded="lg"
           boxShadow="  rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 150px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
   
          textAlign="center" 
        >
          <Text fontWeight="bold" fontSize="md" color='green.700' style={{fontFamily: "'Noto Sans JP', serif", fontSize:'17px'}} >
            {e.label}
          </Text>
          <Text mt={2} color="gray.600" style={{fontFamily: "'Noto Sans JP', serif", fontSize:'15px'}} >
            {e.description}
          </Text>
        </Box>

          ))}
             
      </VStack>

 
    }

    </VStack>
  );
};





export default function LandingPage() {

   const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);

const [activeIndex, setActiveIndex] = React.useState(1);



    return ( isPortrait ?

<VStack flex={1}>
      <Box flex={1} position="relative">
        <Image
          source={MainPage} // Replace with your image URL
          alt="background-image"
          width="100%"
          height="100vh"
          objectFit="cover"
        />


        <Center position="absolute" bottom="10%"   w='100%' transform="translate(-50%, -50%)">
         
          <Link to="/summarize">
                   
                      <button className="big_button" style={{
    textDecoration: 'none',
    minWidth: '20rem',
    padding: '0px',
    fontSize: isPortrait ? '16px' : null,
    marginTop: isPortrait ? '1.2rem' : "2.5rem",
    height: isPortrait ? '45px' : "50px",
    color: "white", 
    background: 'transparent', /* Make background transparent */
    border: '2px solid white', /* Optional: Add border if needed */
}}>
    <span>{isPortrait ? "Summarize Now" : "Get Started"} <span>&#187;</span></span>
</button>
  </Link>  
        </Center>
      </Box>
    </VStack>

    :


     <VStack minHeight={isPortrait ? null: '100%'}  >
      <Divider  />
<VStack space="3" mt={isPortrait ?'5vh' : '10vh'} alignItems='center' >
      <Text  style={{ fontFamily: "'Noto Sans JP', serif", fontSize: isPortrait ? '3rem' :"5.3rem", 
      fontWeight:'bold',textAlign:'center',lineHeight: isPortrait ? '1.45':null

  }} > Read Less. Do More. </Text>
     
               
{isPortrait ?   <Text mt={3} mx={4} w={ isPortrait ? '98%': "80%"} textAlign="center"  
style={{ fontFamily: "'Noto Sans JP', serif", fontSize: isPortrait ? '1.2rem' : "1.2rem",lineHeight:'2.1rem',
fontWeight:"bold", lineHeight:'1.9' }} 
> If you spend too much time going through lengthy documents, use QuickSum to summarize documents into sections and grasp the essential information faster and save valuable time. </Text>
:   <Text w={ "70%"} mt={5} textAlign="center"  style={{ fontFamily: "'Noto Sans JP', serif", fontSize: isPortrait ? '.9rem' : "1.2rem",lineHeight:'2.1rem' }} > If you spend too much time going through lengthy documents, try summarizing the documents to extract key points into sections and interact with them using QuickSum. You’ll grasp the essential information faster and save valuable time. </Text>}
        


          <Link to="/summarize">
                   
                       <button className="  big_button" style={{
                        textDecoration:'none',minWidth:'20rem',padding:'0px',fontSize:isPortrait ?'16px':null,
                         marginTop: isPortrait ? '1.2rem': "2.5rem", height: isPortrait ? '45px': "50px", color:"white", background:'green'
                    }} ><span> {isPortrait ? "Summarize Now": "Get Started"} <span>&#187;</span></span> </button>  </Link>  
               

            {isPortrait ?  <VStack space={3}  alignItems='center' w='100%' my={'1%'} px={6} >


                </VStack>  :<HStack justifyContent='space-around' w='100%' my={'4%'} px={6} >

                <img src={VSC}      />
                  <iframe width={isPortrait ? "100%":"70%"} height='600px' src="https://www.youtube.com/embed/0ayiZmfEq9Y" title="How to turn Lengthy Documents into Summarized Contents You Can Interact With using QuickSum" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <img  src={SRA}      />

                </HStack>}



  <QuickSumBenefits />

  <VStack space={3} w={ isPortrait ?'95%' :'80%'} mb='5%' alignItems="center" mt={2}  >
      <Text textAlign="center"  style={{ fontFamily: "'Noto Sans JP', serif", fontSize: isPortrait ? '1.5rem': "1.8rem", fontWeight:'bold'}} > Power up with Premium</Text>
      <Text mb={4} textAlign="center"  style={{ fontFamily: "'Noto Sans JP', serif", fontSize: isPortrait ?"15px": "18px", }} >Unlock automated intelligent document processing, advanced data extraction, and seamless workflows for businesses of all sizes.</Text>
      

     
<UpgradeButton />

        </VStack>



      <Text textAlign='center'  style={{ fontFamily: "'Noto Sans JP', serif", fontSize: isPortrait ? '1.6rem':"3.3rem", fontWeight:'bold'}} > QuickSum is Best For...</Text>
        <SummarizeAiUseCases />

<Box  mb='8%' ></Box>
        </VStack>



        </VStack>
    )
}

const HeaderStyle = {
    minHeight: "78.5vh",
    marginLeft:'3rem',
    marginTop:'3rem',
    marginBottom:'3rem',
}

const MobileHeaderStyle = { 
    margin :'.6rem', 
}