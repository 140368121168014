import React, { useState } from "react";
import { VStack, Box, Text, Button, Radio, Input, Modal,useMediaQuery,Center,HStack,Divider } from "native-base";
import {generate_questions} from '../assets/steps_process'




const QuizComponent = ({updateState=f=>f,model=f=>f,state={},RenderLoading=f=>f}) => {
  const [answers, setAnswers] = useState({});
  const [questions, setQuestions] = useState([]);
  const [textAnswers, setTextAnswers] = useState({});
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [finalScore, setFinalScore] = useState(0);
  const [message, setMessage] = useState("");
  const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);



React.useEffect(() => {
  const has_questions = localStorage.getItem('has_questions')
  if ( !has_questions && state.features?.value === "Quiz Me" && state.difficulty?.value) {
    getQuestions();
    setModalVisible();

  }
}, [state.difficulty?.value]); // Use the actual value, not an object spread




const removeConsecutiveBr = (text) => {
    return text
        .replace(/(<br\s*\/?>\s*)+/gi, '\n')  
        .replace(/`/g, '')  
        .replace('json', '') 
        .trim();  
};



 


const getQuestions = React.useCallback(async () => {
  try {
    setIsLoading(true);
    const result = await model.generateContent(generate_questions({ ...state }));
    const generatedText = result.response['candidates'][0]['content']['parts'][0].text;
    setQuestions(JSON.parse(removeConsecutiveBr(generatedText)));
    localStorage.setItem('has_questions',true)
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.error("Error:", error);
    throw error;
  }
}, [model, state]);





 const handleSelect = (question, option) => {
  setAnswers((prevAnswers) => ({
    ...prevAnswers,
    [question]: String(option),
  }));
};

const handleTextChange = (question, text) => {
  setTextAnswers(prevTextAnswers => ({
    ...prevTextAnswers,
    [question]: text,
  }));
};





  const getResultMessage = (scorePercentage) => {
    if (scorePercentage < 40) return "🤬 You're absolute failure! Even a potato knows more!";
    if (scorePercentage < 55) return "😒 Bruh, did you even try?";
    if (scorePercentage < 65) return "🙂 Not bad! You're getting there!";
    return "🚀 Amazing! You are a genius!";
  };

  const handleSubmit = () => {
    let correctCount = 0;


    questions.forEach((q) => {
      if (q.question_type === "Text" ||q.question_type.trim() === "Fill-in-the-Blanks" ) {
        if (String(textAnswers[q.question] || "").replaceAll(" ","").trim().toLowerCase() === String(q.correct_answer||"").replaceAll(" ","").trim().toLowerCase()) {
          correctCount++;
        }
      } else {
        if (String(answers[q.question]) === String(q.correct_answer) ) {
          correctCount++;
        }
      }
    });

    const totalQuestions = questions.length;
    const scorePercentage = (correctCount / totalQuestions) * 100;

    console.log('adebaimim correctCount',correctCount)
    setFinalScore(scorePercentage);
    setMessage(getResultMessage(scorePercentage));
    setModalVisible(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return ( isLoading ? <RenderLoading w={isPortrait ?'60px':'130px'} />:
  !isModalVisible ? 
    <VStack space={4} padding={0}   >
    <Text my={1}  style={{fontSize: isPortrait ? '15px': "1.2rem", marginBottom:'10px', textAlign:'center',
    fontFamily: "'Noto Sans JP', serif",fontWeight:"bold"   }}> {Object.keys(answers).length} of {questions.length} </Text>
      {questions.map((q, index) => (
        <Box key={index} padding={4} borderRadius={10} bg="#eeee" style={{boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"}} >
           <Text fontSize="md" mb={2} color='black' bold style={{fontFamily: "'Noto Sans JP', serif"}}>{index+1}) {q.question}</Text>

          {q.question_type === "Multiple-Choice Questions (MCQs)" || q.question_type === "True/False" ? (
            <Radio.Group
              name={q.question} 
              onChange={(value) => handleSelect(q.question, value)}
            >
              {q.options.map((option) => (
                <Radio color='black' key={option} value={option} my={1} selected={option === answers[q.question] } >
                  {option}
                </Radio>
              ))}
            </Radio.Group>
          ) : q.question_type === "Text"||q.question_type.trim() === "Fill-in-the-Blanks" ? (
            <input   style={{ color: 'black', backgroundColor: 'white',width:'100%', marginTop:'2px', border:'1px solid #eee' }}
              placeholder="Type your answer" bg='white'
              value={textAnswers[q.question] || ""}
              onChange={(e) => handleTextChange(q.question, e.target.value)}
            />
          ) : <input    style={{ color: 'black', backgroundColor: 'white',width:'100%' }}
              placeholder="Type your answer"
              value={textAnswers[q.question] || ""}
              onChange={(e) => handleTextChange(q.question, e.target.value)}
            />}
        </Box>
      ))}

      <Button colorScheme="green" onPress={handleSubmit}>
        Submit
      </Button>

      
    </VStack>:
    <VStack space={2} >

        <Modal.Content style={{border:'1px solid silver'}} >
          <Modal.Header>Result</Modal.Header>
          <Modal.Body bg='#eee'>
          <Center>
            <Text color='green' fontSize="3rem" bold>{finalScore.toFixed(0)}%</Text>
            <Text textAlign='center' mt={2}>{message}</Text>
                    </Center>
          </Modal.Body>
        
        </Modal.Content>


 <VStack space={4} padding={0}  mt={2}  >
 
      {questions.map((q, index) => (<VStack px={2} style={{boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"}} >
       <Text fontSize="md" my={2} color='black' bold style={{fontFamily: "'Noto Sans JP', serif"}}>{index+1}) {q.question}</Text>
              <Text fontSize="md"  mb={2}    style={{fontFamily: "'Noto Sans JP', serif"}}><span style={{color:'black',fontWeight:'bold'}} >Selected Answer:</span>  {answers[q.question]||textAnswers[q.question]||"Not Provided"}</Text>
       <Text fontSize="md" mb={2}   style={{fontFamily: "'Noto Sans JP', serif"}}><span style={{color:'green',fontWeight:'bold'}} >Correct Answer:</span>  {q.correct_answer}</Text>
       <Text fontSize="md" mb={2}   style={{fontWeight:'bold',fontFamily: "'Noto Sans JP', serif", color:answers[q.question] === q.correct_answer ?'green':'red'}}><span  >Result:</span>  {answers[q.question] === q.correct_answer ? 'Pass':"Fail"}</Text>
       <Divider />
       </VStack>
      ))}
<HStack alignItems='center' justifyContent='space-between' >

<Button.Group mb={3} isAttached colorScheme="blue" mx={{
    base: "auto",
    md: 0
  }} size="md">
     <Button   onPress={()=>{
        getQuestions()
setModalVisible()

      }}>
        Re-Quiz
      </Button>
       <Button variant="outline"
 
  onPress={() => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
  }}
>
  Go Up
</Button>
 
    </Button.Group>
    


    </HStack>
    </VStack>



        </VStack>

  );
};

export default React.memo(QuizComponent);
