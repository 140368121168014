import React, { useState } from 'react';
import { Box, HStack, VStack, Input, IconButton, Text, Spinner, ScrollView } from 'native-base';
import HtmlViewer from './HtmlViewer';

const ChatBox = ({state={},handleChatRequest=f=>f,updateState=f=>f,askLoading=false}) => {
  const [currentMessage, setCurrentMessage] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);


React.useEffect(()=>{
localStorage.setItem('currentMessage',currentMessage)
},[currentMessage])

  const handleSend = () => {
    if (currentMessage.trim()) {
      setResponse('');

      handleChatRequest(currentMessage.trim())

      setCurrentMessage('');
    }
  };

  return (
    <Box safeArea p="4" flex="1" bg="#f7f7f7" borderRadius="lg" shadow="3">
    

      <ScrollView flex="1" bg="white" p="3" borderRadius="lg">
       
       <HtmlViewer htmlContent={state.askResponse||"Ask your question from your document..."} minHeight={'30vh'} maxHeight={'50vh'} />
      </ScrollView>

      <HStack space={2} mt="3" alignItems="center">
        <Input
          flex="1"
          placeholder="Type your question..."
          value={currentMessage||localStorage.getItem('currentMessage')}
          onChangeText={setCurrentMessage}
          onSubmitEditing={handleSend}
          bg="white"
          rounded="full"
          fontSize="15px"
          px="4"
          py="3"
          _focus={{ borderColor: '#4a90e2' }}
          style={{fontFamily: "'Noto Sans JP', serif"}}
        />
     {  askLoading ?
          <HStack justifyContent="center" mb="2">
            <Spinner color="#4a90e2" size="lg" />
          </HStack> : <IconButton onPress={handleSend}  

          icon={  <svg width="20" height="20" fill="currentColor" class="bi bi-send-arrow-up" viewBox="0 0 16 16" style={{color:'white'}}>
  <path fill-rule="evenodd" d="M15.854.146a.5.5 0 0 1 .11.54l-2.8 7a.5.5 0 1 1-.928-.372l1.895-4.738-7.494 7.494 1.376 2.162a.5.5 0 1 1-.844.537l-1.531-2.407L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM5.93 9.363l7.494-7.494L1.591 6.602z"/>
  <path fill-rule="evenodd" d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-5.354a.5.5 0 0 0-.722.016l-1.149 1.25a.5.5 0 1 0 .737.676l.28-.305V14a.5.5 0 0 0 1 0v-1.793l.396.397a.5.5 0 0 0 .708-.708z"/>
</svg>}
          borderRadius="full"
          bg="#4a90e2"
          onPress={handleSend}
          _hover={{ bg: '#357ab8' }}
        />
      }
      </HStack>
    </Box>
  );
};

export default ChatBox;
