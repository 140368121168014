import React, { useRef } from 'react';
import htmlParser from 'html-react-parser';
import html2pdf from 'html2pdf.js';
import {VStack,HStack,useMediaQuery} from 'native-base'


const HtmlViewer = ({ htmlContent="",state={},minHeight='0px',maxHeight=null }) => {
  const contentRef = useRef(null);

 const [edit,setEdit] = React.useState(false)

const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);

const handleCopy = () => {
    const range = document.createRange();
    range.selectNodeContents(contentRef.current);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    alert("Content copied");
  };


 
function convertCodeToTextarea(input) {
  return input.replace(/```(\w+)?\s*([\s\S]*?)\s*```/g, (match, lang, code) => {
    return `<textarea class="code-box ${lang || ''}" readonly>${code.trim()}</textarea>`;
  });
}

  return (

    <VStack space={2} >
    <HStack space={4} justifyContent="flex-end" >
     
      </HStack>
      <button
        style={{
          position: 'absolute',
          top: '-13px',
          right: '70px',
          fontSize: '12px',
          padding: '5px',
          cursor: 'pointer',
          zIndex: 1,
           background: 'gray',
    border: '0px solid',
    color: 'white',
    minWidth: '64px'
        }}
        onClick={e=>setEdit(!edit)}
      >
       {edit ? "Stop Editing":"Edit"}
      </button><button
        style={{
          position: 'absolute',
          top: '-13px',
          right: '0px',
          fontSize: '12px',
          padding: '5px',
          cursor: 'pointer',
          zIndex: 1,
           background: 'green',
    border: '0px solid',
    color: 'white',
    width: '64px'
        }}
        onClick={handleCopy}
      >
        Copy
      </button>

       <div  contentEditable={edit} ref={contentRef} style={{ padding: '0px',  lineHeight:'2rem',minHeight:minHeight,
       maxHeight:maxHeight,fontFamily: "'Noto Sans JP', serif",fontSize:isPortrait ?'14px':null
      }}>
        {htmlParser(convertCodeToTextarea(htmlContent))}
      </div>
    
    </VStack>
  );
};

// Optional: Add some styling for the export button
const exportButtonStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '5px'
};

// Optional: Add some styling for the export button
const saveButtonStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: '#1e53a2',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '5px',
  'width': '140px'
};

const contentStyle = {

 
};


export default HtmlViewer;
