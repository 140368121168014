// UpgradeButton.js
import React from 'react';
import { Button, Icon,Link } from 'native-base';

const UpgradeButton = () => {
const handleUpgradeClick = () => {
    const subject = encodeURIComponent('Upgrade to Premium');
    const body = encodeURIComponent(
      `Hello,

I am interested in upgrading to the Premium plan. Please provide me with the necessary details to proceed.

Thank you.`
    );
    
    window.location.href = `mailto:support@quicksum.com?subject=${subject}&body=${body}`;
  };

  
  return (
    <Button  style={{'color':'white',borderRadius:'50px'}} onPress={handleUpgradeClick}
      leftIcon={
        <svg  width="20" height="20" fill="currentColor" class="bi bi-cassette" viewBox="0 0 16 16">
  <path d="M4 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2m9-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 6a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2z"/>
  <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zM1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-.691l-1.362-2.724A.5.5 0 0 0 12 10H4a.5.5 0 0 0-.447.276L2.19 13H1.5a.5.5 0 0 1-.5-.5zM11.691 11l1 2H3.309l1-2z"/>
</svg>
      }
      mode='outline'
      colorScheme="success"
      _text={{ fontWeight: 'small', fontFamily: "'Noto Sans JP', serif", fontSize:"18px", }}
    >
      Upgrade to Premium
    </Button>
  );
};

export default UpgradeButton;
